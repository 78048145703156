import { UserRolesType } from 'types/user';

const role: UserRolesType = {};

export function getUserRole(userRoles: UserRolesType): keyof typeof role {
  let returnRole: keyof typeof userRoles = 'ticketAgent';
  if (userRoles.admin) {
    returnRole = 'admin';
  } else if (userRoles.superManager) {
    returnRole = 'superManager';
  } else if (userRoles.manager) {
    returnRole = 'manager';
  } else if (userRoles.accountant) {
    returnRole = 'accountant';
  }

  return returnRole;
}

export function getUserRoleObject(userRole: keyof typeof role): UserRolesType {
  let returnRoleObj: UserRolesType = {
    ticketAgent: true,
    manager: false,
    superManager: false,
    admin: false,
    accountant: false,
  };
  if (userRole === 'admin') {
    returnRoleObj = {
      ticketAgent: true,
      manager: true,
      superManager: true,
      admin: true,
      accountant: true,
    };
  } else if (userRole === 'manager') {
    returnRoleObj = {
      ticketAgent: true,
      manager: true,
      superManager: false,
      admin: false,
      accountant: false,
    };
  } else if (userRole === 'accountant') {
    returnRoleObj = {
      ticketAgent: true,
      manager: false,
      superManager: false,
      admin: false,
      accountant: true,
    };
  }

  return returnRoleObj;
}
