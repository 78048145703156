import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export const SLTimeZone = 'Asia/Colombo';

/**
 * Sri Lanka Local Time
 * @param {Date} date - Date - default value - Today
 * @param {string} zone - TimeZone - default value - 'Asia/Colombo'
 * @returns {Date} zonedTime - Sri Lanka Local Time
 */
export const localTime = function localTime(date = new Date(), zone = SLTimeZone): Date {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const utcDate = zonedTimeToUtc(date, timeZone);
  const zonedTime = utcToZonedTime(utcDate, zone);
  return zonedTime;
};

export const fromLocalTime = function fromLocalTime(date = new Date()): Date {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const utcDate = zonedTimeToUtc(date, SLTimeZone);
  const zonedTime = utcToZonedTime(utcDate, timeZone);
  return zonedTime;
};
