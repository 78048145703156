export function sorter<T>(a: T, b: T, field: keyof T): number {
  let returnSorter = 0;
  if (a[field] > b[field]) {
    returnSorter = -1;
  }
  if (b[field] > a[field]) {
    returnSorter = 1;
  }
  return returnSorter;
}
