import { groupBy } from 'ramda';
import { Schedule } from 'types/schedule';
import { ScheduleByMovie } from 'types/schedule-by-movie';

export const scheduleGroupBy = function scheduleGroupBy(schedules: Schedule[]): ScheduleByMovie {
  const groupByMovie = groupBy((schedule: Schedule) => schedule.movie.id)(schedules);
  const groupedByMovieCinema: ScheduleByMovie = {};
  Object.keys(groupByMovie).forEach((movieId) => {
    const movieData = groupByMovie[movieId];
    const groupByCinema = groupBy((schedule: Schedule) => schedule.cinema.id)(movieData);
    groupedByMovieCinema[movieId] = groupByCinema;
  });
  return groupedByMovieCinema;
};
